<template>
  <v-container>
    <div style="height:60px; width: 1100px;" class="container">
      <dashboard-title class="font  font-md text-uppercase"></dashboard-title>
    </div>
    <!--    border: 2px solid-->
    <div style="width: 100%">
      <v-card
        class="d-flex flex-wrap justify-space-around mb-6"
        color="transparent"
        flat
        ><information-card
          description="All Staffs"
          :total="totalCount.staffCount"
        />
        <information-card
          description="Members"
          :total="totalCount.membersCount"
        />
        <information-card
          description="New Members Today"
          :total="totalCount.totalMembersToday"
        />
        <information-card
          description="Overall Transactions"
          :total="totalCount.totalTransactions"
        />
      </v-card>
    </div>
    <v-row style="width: 100%">
      <v-col cols="12" md="12" lg="12">
        <h4 class=" font font-md text-uppercase mx-6 pb-1">
          Newly Added Members Today
        </h4>

        <v-data-table
          :items="members"
          :headers="headers"
          :options.sync="options"
          :server-items-length="paginate.total"
          class="font box-shadow-light font-weight-medium text-capitalize font font-md mx-6"
          loading-text="Loading... Please wait"
          :footer-props="{
            itemsPerPageOptions: [20, 30, 40],
          }"
        >
          <template #item.name="{item}">
            <v-chip small label color="primary">
              {{ item.firstName }} {{ item.lastName }}
            </v-chip>
          </template>
          <template #item.birthDate="{item}">
            <v-chip small label class=" font font-md font-weight-bold">
              {{ item.birthDate | dateFormat }}
            </v-chip>
          </template>
          <template #item.createdAt="{item}">
            {{ item.createdAt | dateFormat }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DashboardTitle from '@/components/component/DashboardTitle';
import InformationCard from '@/components/component/InformationCard';
import { mapGetters } from 'vuex';
import moment from 'moment';
export default {
  components: { InformationCard, DashboardTitle },
  computed: {
    ...mapGetters({
      totalCount: 'reports/getTotalCount',
      members: 'reports/getNewMembers',
      paginate: 'reports/getPagination',
    }),
  },
  data() {
    return {
      options: {},
      headers: [
        { text: '', value: 'avatar' },
        { text: 'Full Name', value: 'name' },

        { text: 'Date Of Birth', value: 'birthDate' },
        { text: 'Phone Number', value: 'phoneNo' },
        { text: 'Address', value: 'address1' },
        { text: 'Registered At', value: 'createdAt' },
      ],
    };
  },
  watch: {
    options: {
      handler(value) {
        const { page, itemsPerPage } = value;
        this.$store.dispatch('reports/newlyAddedMembers', {
          page,
          size: itemsPerPage,
        });
      },
      deep: true,
    },
  },
  filters: {
    dateFormat(value) {
      return moment(value).format('YYYY-MM-DD');
    },
  },
  created() {
    let payload = {
      page: 1,
      size: 20,
    };
    this.$store.dispatch('reports/newlyAddedMembers', payload);
    this.$store.dispatch('reports/totalCount');
  },
};
</script>
<style>
.container {
  display: flex;
  flex-direction: column;
  wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  alignt-content: space-around;
}

.box {
  height: auto;
  width: auto;
  padding: 3%;
}
</style>
